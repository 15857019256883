/* eslint-disable */

import { Suspense, lazy } from "react";

import AuthGuard from "./components/guards/AuthGuard";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import EventGuard from "./components/guards/EventGuard";
import EventLayout from "./components/dashboard/EventLayout";
import FormGuard from "./components/guards/FormGuard";
import GuestGuard from "./components/guards/GuestGuard";
import LoadingScreen from "./components/LoadingScreen";
import MailLayout from "./components/dashboard/mail/MailLayout";
import NavbarLayout from "./components/NavbarLayout";
import { Navigate } from "react-router";
import RoleGuard from "./components/guards/RoleGuard";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages

const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
const PasswordRecovery = Loadable(
  lazy(() => import("./pages/authentication/PasswordRecovery"))
);
const PasswordReset = Loadable(
  lazy(() => import("./pages/authentication/PasswordReset"))
);
const Register = Loadable(
  lazy(() => import("./pages/authentication/Register"))
);

// Dashboard pages

// Dashboard - Account pages

const AccountLayout = Loadable(
  lazy(() => import("./components/dashboard/AccountLayout"))
);
const AccountGeneralSettings = Loadable(
  lazy(() => import("./pages/dashboard/account/AccountGeneralSettings"))
);
const AccountSecuritySettings = Loadable(
  lazy(() => import("./pages/dashboard/account/AccountSecuritySettings"))
);

// Dashboard - Event pages

const Calendar = Loadable(
  lazy(() => import("./pages/dashboard/event/Calendar"))
);
const MailNew = Loadable(
  lazy(() => import("./pages/dashboard/event/mail/MailNew"))
);
const MailView = Loadable(
  lazy(() => import("./pages/dashboard/event/mail/MailView"))
);

const Applications = Loadable(
  lazy(() => import("./pages/dashboard/event/Applications"))
);
const Statistics = Loadable(
  lazy(() => import("./pages/dashboard/event/Statistics"))
);
const UsersOverview = Loadable(
  lazy(() => import("./pages/dashboard/event/UsersOverview"))
);
const SocialProfile = Loadable(
  lazy(() => import("./pages/dashboard/event/SocialProfile"))
);
const FormCreate = Loadable(
  lazy(() => import("./pages/dashboard/event/FormCreate"))
);
const FormSubmit = Loadable(
  lazy(() => import("./pages/dashboard/event/FormSubmit"))
);
const ControlPanel = Loadable(
  lazy(() => import("./pages/dashboard/event/ControlPanel"))
);
const Files = Loadable(lazy(() => import("./pages/dashboard/event/Files")));

// Home page

const Home = Loadable(lazy(() => import("./pages/dashboard/Home")));

// Error pages

const NotFound = Loadable(lazy(() => import("./pages/NotFound")));

const routes = [
  {
    path: "*",
    element: <NavbarLayout />,
    children: [
      {
        path: "authentication",
        children: [
          {
            path: "login",
            element: (
              <GuestGuard>
                <Login />
              </GuestGuard>
            )
          },
          {
            path: "password-recovery",
            element: (
              <GuestGuard>
                <PasswordRecovery />
              </GuestGuard>
            )
          },
          {
            path: "password-reset",
            element: (
              <GuestGuard>
                <PasswordReset />
              </GuestGuard>
            )
          },
          {
            path: "register",
            element: (
              <GuestGuard>
                <Register />
              </GuestGuard>
            )
          }
        ]
      },

      {
        path: "event/:eventId/apply",
        children: [
          {
            path: "",
            element: (
              <FormGuard>
                <FormSubmit />
              </FormGuard>
            )
          },
          {
            path: "register",
            element: (
              <FormGuard register>
                <FormSubmit />
              </FormGuard>
            )
          }
        ]
      },
      {
        path: "*",
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: "*",
            element: <Home />
          },
          {
            path: "account",
            element: <AccountLayout />,
            children: [
              {
                path: "*",
                element: <AccountGeneralSettings />
              },
              {
                path: "security",
                element: <AccountSecuritySettings />
              }
            ]
          },
          {
            path: "event/:eventId",
            element: (
              <EventGuard>
                <EventLayout />
              </EventGuard>
            ),
            children: [
              {
                path: "*",
                element: <SocialProfile />
              },
              // {
              //   path: "scheduling",
              //   element: <Calendar />
              // },
              // {
              //   path: "scheduling",
              //   element: <Calendar />
              // },
              {
                path: "users",
                element: (
                  <RoleGuard roleLevels={[0, 1]}>
                    <UsersOverview />
                  </RoleGuard>
                )
              },
              {
                path: "email",
                element: (
                  <RoleGuard roleLevels={[0, 1]}>
                    <MailLayout />
                  </RoleGuard>
                ),
                children: [
                  {
                    path: "*",
                    element: <Navigate to="./sent" replace />
                  },
                  {
                    path: "new",
                    element: <MailNew />
                  },
                  { path: "label/:customLabel", element: <MailView /> },
                  {
                    path: "label/:customLabel/:emailId",
                    element: <MailView />
                  },
                  {
                    path: ":systemLabel",
                    element: <MailView />
                  },
                  {
                    path: ":systemLabel/:emailId",
                    element: <MailView />
                  }
                ]
              },
              {
                path: "files",
                element: <Files />
              },
              {
                path: "statistics",
                element: (
                  <RoleGuard roleLevels={[0]}>
                    <Statistics />
                  </RoleGuard>
                )
              },
              // {
              //   path: "statistics",
              //   element: (
              //     <RoleGuard roleLevels={[0]}>
              //       <Statistics />
              //     </RoleGuard>
              //   )
              // },
              {
                path: "applications",
                element: (
                  <RoleGuard roleLevels={[0, 1]}>
                    <Applications />
                  </RoleGuard>
                )
              },
              {
                path: "form",
                element: (
                  <RoleGuard roleLevels={[0]}>
                    <FormCreate />
                  </RoleGuard>
                )
              },
              {
                path: "control-panel",
                element: (
                  <RoleGuard roleLevels={[0]}>
                    <ControlPanel />
                  </RoleGuard>
                )
              }
            ]
          },
          {
            path: "*",
            element: <NotFound />
          }
        ]
      }
    ]
  }
];

export default routes;
